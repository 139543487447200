import React from "react"
import PropTypes from "prop-types"
import { motion } from 'framer-motion'
import { revealInOut } from '../helpers/transitionHelper'

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.6,
      delayChildren: duration,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}


export default function Intro(props) {
return (
<motion.div variants={item} initial="initial" animate="enter" exit="exit">
  <motion.div  className="intro-top grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

    <div className="border-b flex flex-row">
      <div className="text-v uppercase pb-6 md:pt-8 lg:pt-10 px-1  lg:px-3 tracking-widest mob:hidden self-end">Intro</div>
    </div>

    <div className="intro-content border-b border-l">
      <div className="md:grid grid-cols-2 px-6 md:px-10 md:-mr-10 h-full">

        <motion.div
          className={`md:h-full md:flex flex-col justify-end`}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={container}>
          <h1 className="mob:pt-10 mob:mb-40 text-3xl md:text-4xl lg:text-5xl xl:text-6xl pr-10 md:pr-20 xl:pr-32 mb-4 leading-none">
            <span className="block relative overflow-hidden">
              <motion.span variants={revealInOut} className="block">{props.introHeader}</motion.span>
            </span>
            <span className="block relative overflow-hidden">
              <motion.span variants={revealInOut} className="block">{props.introSubHeader}</motion.span>
            </span>
          </h1>
        </motion.div>

        <motion.div variants={item} transition="easeInOut"
          className={props.bgColor+` intro-summary md:border-l md:px-10 md:-ml-5 h-full md:flex flex-col justify-end xl:pr-32`}>
          <p className="text-1xl lg:text-xl xl:text-2xl mb-4 leading-snug">{props.introSummary}</p>
        </motion.div>

      </div>
    </div>

    <div className="border-b border-l"></div>

  </motion.div> 
</motion.div> 

)

}

Intro.propTypes = {
  introHeader: PropTypes.array,
  introSubHeader: PropTypes.array,
  introSummary: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderOpacity: PropTypes.string,
}

Intro.defaultProps = {
  borderColor: '',
}



